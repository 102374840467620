import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Flickity from "react-flickity-component"

import whiteArrowSvg from "../images/arrow-white-2.svg"

import NwHelmet from  "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import flickityA11y from "../utils/flickity-a11y"

const Wrapper = styled.div`
  min-height: 100%;
  padding: 1rem 0 4.625rem;
  background: ${props => props.bgColor || "white"};
`

const StyledFlickity = styled(Flickity)`
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  max-width: 80rem;
  margin: 0 auto;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  .carousel-cell {
    width: 100%;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-prev-next-button {
    position: fixed;
    bottom: 1.125rem;
    width: 2.375rem;
    height: 2.375rem;
    padding: 0;
    border: 0.125rem solid white;
    border-radius: 50%;
    background: ${props => props.bgColor || "black"};

    ::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url(${whiteArrowSvg}) no-repeat 0.45rem center;
      background-size: 1.375rem 2.125rem;
    }

    svg {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    left: 1.375rem;
    transform: rotate(180deg);
    filter: drop-shadow(0 -0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  }

  .flickity-prev-next-button.next {
    right: 1.375rem;
    filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  }

  .flickity-prev-next-button:disabled {
    display: none;
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 25rem;
  height: auto;
  margin: 0 0.5rem;

  &.is-first {
    margin-left: 0;
  }

  &.is-last {
    margin-right: 0;
  }
`

let mhbFlkty

export default function MyHospitalBookTemplate({
  data: { slideshowsYaml: { images, bgColor } },
  pageContext: { lang, pagePath, title },
}) {
  const flickityOptions = {
    pageDots: false,
    percentPosition: true,
    on: {
      ready: flickityA11y,
      change: () => flickityA11y(false),
    },
  }

  const firstImage = images && images.length && images[0]
  const firstImageFileType = firstImage ?
    (firstImage.image.toLowerCase().endsWith(".jpg") ? "jpeg" : firstImage.image.split(".").slice(-1)[0]).toLowerCase() :
    null

  return (
    <Wrapper bgColor={bgColor}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={firstImage && {
          url: firstImage.image,
          type: `image/${firstImageFileType}`,
          alt: firstImage.altText,
        }}
      />
      <Header
        lang={lang}
        title={title}
        room={3}
        color={bgColor || "black"}
        titleColor="#005069"
      />
      {images && images.length > 0 &&
        <div>
          <StyledFlickity
            bgColor={bgColor}
            options={flickityOptions}
            flickityRef={c => mhbFlkty = c}
          >
            {(images || []).map((image, index) => (
              <Image
                key={image.image}
                src={`${image.image}?nf_resize=fit&w=800`}
                alt={image.altText}
                className={`${!index ? "is-first" : ""} ${index === images.length - 1 ? "is-last" : ""}`}
              />
            ))}
          </StyledFlickity>
        </div>
      }
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $title: String) {
    slideshowsYaml(lang: { eq: $lang }, title: { eq: $title }) {
      images {
        image
        altText
      }
      bgColor
    }
  }
`
